// import tableUtil from '@/components/global/TableModal/util' //组件
import { getProductList, submit, getCustomerMarketModel, uploadFile } from "./api";
import Step from "@/components/global/Step/Step.vue";
import ProductList from "../components/ProductList.vue";
import ProductModal from "../components/ProductModal.vue";
import { getInitInfor, getMatklByOrg, getshAddress } from './api'
import axios from "axios";
import { publicPath } from "@/common/constant.js";
import { mapState } from "vuex";
import IdentificationModal from "../components/IdentificationModal.vue";
import IdentificationEditModal from "../components/IdentificationEditModale.vue";
import { queryOrg } from "@/views/activityConfigurationList/edit/api";
import { getProductListNew } from "@/views/defectiveRetirementNew/hisenseRrturnInput/components/api";
import { getBscList } from "@/views/distributorWarehouse/addDistributorWarehouse/api";

export default {
  name: "Home",
  components: {
    Step,
    ProductList,
    ProductModal,
    IdentificationModal,
    IdentificationEditModal
  },

  data() {
    return {
      BussiseGroup: [], // 商家数组
      shopId: '', // 商家id
      rowSelectionType: "checkbox", //是否编辑
      selRow: {}, //当前编辑的行
      modelShow: false,
      bankChecked: true,
      visible: false,
      steps: ["录入退货信息", "确认订单信息", "提交订单"],
      currentStep: 2,
      deliveryOptions: [],
      prods: [],
      // 开票户头账号
      choseAccounts: [],
      // 开票户头id
      invoiceId: "",
      //是否允许提前发货
      allowAdvance: true,
      // 用户选择截止时间
      // endTime: '',
      // 经销商下拉选择框
      distr: false,
      // 经销商地址
      distrAddress: "",
      distrAdd: false,
      distrName: "",
      // 经销商地址
      orderDis: [],
      // 选择直配到分销时填写客户单号
      orderNumber: "",
      // 用户详细地址
      userDetailAddress: "",

      // 省市区三级联动
      secondCity: "",
      // 接收子组件选中的商品
      sonChectItme: [],
      receiveAdd: false,
      // 收货地址/统仓地址
      receive: "",
      // 销售组织id
      receiveID: "",
      // 收货地址类型 obj.shareFlag=='Y'?'统仓地址':'收货地址'
      // 配送方式ID
      sendId: "",
      // 分销商地址选择
      distributionAddress: [],
      // 输入输入内容
      searchInt: "",
      // 省
      provices: [],
      //市
      getCity: [],
      // 城镇
      getTown: [],
      // 城镇下一级
      qu: [],
      // 省市区选择
      proObj: {},

      // 经销商地址列表
      choseDistribution: [],
      // 分销商列表
      DisList: [],
      // 经销商及经销商地址
      distributionAdd: {
        DISCODE: "",
        DISADD: ""
      },
      // 经销商切换获取地址 接口参数
      DISADDPARAMS: {
        customerId: "",
        orgId: "",
        matklId: ""
      },
      // 要求到货周
      arriveOptions: [],
      selected: "",
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},

      //返利 （返利选项是否选中）
      //返利按钮是否选中
      isTradeChecked: true,
      // 预付比例
      advancePayRate: 0,
      totalAdvanceMoney: 0,
      drawerName: "",
      dissize: false,
      // 点击提交按钮提交的参数
      orgAndGroup: "",
      orderCartIds: {},
      // 配送方式
      hisenseOff: true,
      officehis: [],
      officeCode: "",
      receivehis: "",
      officeHis: false,
      orgCode: "",
      matklId: "",
      orgId: "",
      orgtitle: "",
      obj: {},
      // 提交给后台订单详情
      submitGoods: [],
      endTime: "",
      // 有效截止时间
      startTime: "",
      // 后台接口最大截止时间
      maxEndDate: "",
      // 面包屑
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },

        {
          path: "/hisenseReturn/hisenseRrturnInputNew",
          name: "",
          title: "发起残次退"
        }
      ],
      // 按钮组件loading
      isLoading: false,
      // 页面加载loading
      pageLoadFlag: false,
      pageLoadFlagaa: false,
      //备注
      BZvalue: "",
      // 收货地址
      shippingAddress: {},
      totalOrderedQty: 0,
      totalAmount: 0,
      purchaseOrderItem: [],

      supplierCode: "",
      // 渠道客户
      business: {
        code: "",
        name: ""
      },
      // 基础信息接口数据
      realObj: {},
      // 销售门店
      XSMD: [],
      receiveAddXS: false,
      receiveXS: "",
      receiveXSID: "",
      // 发货仓库
      receiveFH: [],
      receiveAddFH: false,
      receiveFHS: "",
      receiveFHID: "",
      // 收货地址
      receivehisSH: "",
      // 销售类型
      saleOptions: [
        {
          name: "零售",
          id: "retail"
        },
        {
          name: "工程",
          id: "engineering"
        }
      ],
      XSLX: "retail",
      kpf: "",
      productList: [],
      dataCache: [],
      //组件
      columns: [
        {
          label: "型号",
          prop: "model",
          ellipsis: true,
          width: "120",
          key: "model"
        },
        {
          label: "颜色",
          prop: "colour",
          width: "80",
          key: "colour"
        },
        {
          label: "物料组",
          prop: "materialGroupName",
          width: "100",
          ellipsis: true,
          key: "materialGroupName"
        },
        {
          label: "价格(元)",
          prop: "999",
          width: "100",
          key: "999"
        },
        {
          label: "体积(m³)",
          prop: "volume",
          width: "110",
          key: "volume"
        },
        {
          label: "仓库",
          prop: "warehouseName",
          width: "124",
          ellipsis: true,
          key: "warehouseName"
        },
        {
          label: "质量等级",
          prop: "invStatusName",
          width: "110",
          key: "invStatusName"
        },
        {
          label: "补差类型",
          prop: "invStatusTypeName",
          width: "110",
          key: "invStatusTypeName"
        },
        {
          label: "可用库存",
          prop: "bigQty",
          width: "110",
          key: "bigQty"
        },
        {
          label: "销售数量",
          prop: "",
          key: "borderedQty",
          minWidth: 110,
          slot: 'count'
        }
      ],
      dataList: [],
      isVisible: false,
      tabelIsLoading: false,
      searchIpt: "",
      tableTotal: 0,
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      // 城市
      checkCity: "",
      checkTownCode: "",
      checkXZ: "",
      sheng: "",
      materialGroup: [],
      xszhId: "",
      fhckId: "",
      xsmdId: "",
      isEnableOrNot: true,

      //  my
      shopName: '',
      phoneCon: '',
      contacts: '',
      MaterialGroup: [],
      wlz: '',  // 物料组名称
      wlzid: '', // 物料组id
      orgzzId: '', // 销售组织id
      adressName: '', // 地址
      addressId: '', // 地址id
      adressGroup: [], // 地址集合
      transName: '', // 拉货地址transName
      trans: '', // 拉货id
      file: [],
      showTip:false,
      IdentificatioSheetNo:0,// 有无鉴定单号
      showModalIdent:false,// 新增鉴定单弹窗
      editModal:false, // 编辑单条数据弹窗
      editIndex:'',
      editRowInfo: {},
      fileList:[],
      downloadUrlNo: `${process.env.BASE_URL}downLoadTemplate/defectiveReturnTemplate02.xls`,
      downloadUrlHas: `${process.env.BASE_URL}downLoadTemplate/defectiveReturnTemplate01.xls`,
      officeId: '', // 办事处id
      officeArr: [], // 办事处下拉数据
    };
  },
  computed: {
    ...mapState({
      isFenxiaoOrder: state => state.user.userInfo.account.marketModels,
      toPath: state => state.user.toPath
    }),


  },
  watch:{
    productList: {
      immediate: true,
      handler(newValue) {
      }
    },
  },
  mounted() {
    this.getinfor()


  },
  methods: {
    // 产品修改新增操作
    openEdit(item,index){
      // 打开修改弹窗
      this.editModal = true
       // 修改的数据
      this.editRowInfo = item
      // 修改的数据的下标
      this.editIndex = index
    },
    // 新增产品确定获取数据
    getApplyList(e){
      const models = this.productList.map(it => (it.modelName+'-'+it.materialCode+'-'+ it.gicWarehouse));
      e.forEach(it=>{
        // 如果没有想同型号的的数据 就push一条
        if(models.includes(it.modelName+'-'+it.materialCode+'-'+ it.gicWarehouse)) {
          // 如果有的话  就向有的数据中新增一条
          this.productList.forEach(item => {
            if (item.modelName+'-'+item.materialCode +'-'+ item.gicWarehouse == it.modelName+'-'+it.materialCode +'-'+ it.gicWarehouse ) {
              item.list.push(...(it.list))
              item.count += 1
            }
          })
        }
        if(!models.includes(it.modelName+'-'+it.materialCode+'-'+ it.gicWarehouse)){
          this.productList.push((JSON.parse(JSON.stringify(it))))
        }
      })
    },
    getApplyListUpdate(list,count) {
      if(count!== 0 ) {
        this.productList[this.editIndex].list = list
        this.productList[this.editIndex].count = count
      } else {
        this.productList.splice(this.editIndex,1)
      }

    },
    // 切换有无鉴定单号
    changIdentificatioSheetNo(e){
      this.IdentificatioSheetNo = e.target.value;
      this.productList = []
    },

     // 更改地址
     handleChangeBussise(value, e) {
      this.shopId = e.key
      this.shopName = value
        // &&  this.shopName.indexOf("代理") !== -1
       if(this.isFenxiaoOrder.indexOf("17451") !== -1 ) {
         this.showTip = true;
       } else {
         this.showTip = false;
       }

    },
    //  获取下拉信息
    getinfor() {
      getInitInfor().then(res => {
        this.BussiseGroup = res.data.custList
        this.shopName = res.data.custList[0].fullName
        this.shopId = res.data.custList[0].mdmCode
        // 判断是否是分销商
        //   &&  this.shopName.indexOf("代理") !== -1
        if(this.isFenxiaoOrder.indexOf("17451") !== -1 ) {
          this.showTip = true;
        } else {
          this.showTip = false;
        }
        // this.shopName = res.data.defectiveProductModel.customerName
        this.phoneCon = res.data.defectiveProductModel.proposerTel
        this.contacts = res.data.defectiveProductModel.proposerName
        // 销售组织
        this.orderDis = res.data.orgList
        // 拉货方式
        this.deliveryOptions = res.data.edtList
        // this.trans = this.deliveryOptions[0].propertyValue;
        // this.transName = this.deliveryOptions[0].propertyName
      })
    },
    getOrg() {
      let data = {
        type: 2
      }
      queryOrg(data).then(res => {

        this.orgArr = res.data.orgList
      })
    },

    // 选择销售组织后进行物料组织的联动
    handleChangorgId(value, e) {
      this.receive = value;
      this.receiveID = e.key;
      this.receiveAdd = !this.receiveAdd;
      this.getMetarialGroup(e.key);

      this.orderDis.forEach(item => {
        if (item.organizationCode == this.receiveID) {
          this.orgzzId = String(item.id)
        }
      })
      //获取办事处
      getBscList(this.orgzzId).then((res) => {
        this.officeArr = res.data.list;
        this.officeId = res.data.list.length>0? res.data.list[0].code:''
      });
    },
    // 办事处修改
    handleChangofficeId(value){},
    // 物料组织获取
    getMetarialGroup(id) {
      this.MaterialGroup = []
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        orgCode: id
      }
      getMatklByOrg(data).then(res => {
        if (res.data) {
          this.MaterialGroup = this.getNewObj(res.data)
          if (this.MaterialGroup.length > 0) {
            // v-model的物料组名字
            this.wlz = this.MaterialGroup[0].name
            // 物料组id
            this.wlzid = this.MaterialGroup[0].id
            this.getAdress()
          }
        } else {
          this.MaterialGroup = []
        }
      })
    },
    //  根据组织id和物料组id 获取 地址下拉数据
    getAdress() {
      this.adressGrou = []
      let data = {
        orgCode: this.receiveID,
        productModel: this.wlzid
      }
      getshAddress(data).then(res => {
        if (res.data) {
          this.adressGroup = this.getNewObj(res.data)
          if (this.adressGroup.length > 0) {
            this.adressName = this.adressGroup[0].name
            this.addressId = this.adressGroup[0].id
          }
        } else {
          this.adressGroup = []
          this.adressName = ''
          this.addressId = ''
        }
      })
    },
    // 数据重购
    getNewObj(obj) {
      let newArr = [];
      for (let key in obj) {
        newArr.push({
          id: key,
          name: obj[key],
        });
      }
      return newArr
    },
    // 更改物料组
    handleChangemarerial(value, e) {
      this.wlzid = e.key
      this.wlz = value
      this.getAdress()
    },
    // 更改地址
    handleChangeAddress(value, e) {
      this.addressId = e.key
      this.adressName = value
    },
    // 更改拉货方式
    onGiveType(e) {
      this.trans = this.deliveryOptions[e.target.value].propertyValue;
      this.transName = this.deliveryOptions[e.target.value].propertyName
    },

    //搜索按钮事件
    search() {
      if (!this.searchIpt || this.searchIpt.length < 2) {
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000066'
        })
        this.$message.info(msg && msg.msgDesc);
        // this.$message.info("请输入至少两个字符进行搜索！");
        return;
      }
      this.pager.pageNo = 1;
      this.getData();
    },
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getData();
    },
    //调接口获取数据
    getData() {
      debugger
      this.tabelIsLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          model: this.searchIpt,
          colour: "",
          warehouseId: this.receiveFHID,
          invStatusId: "",
          isLock: "",
          dealerMaterialGroupFlag: "",
          materialGroupCode: "",
          materialGroupName: "",
          gicWarehouseType: "",
          invStatusType: "",
          invType: "",
          bigQtyMin: 0,
          orgId: this.receiveID
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize
        }
      };
      getProductList(data).then(res => {
        this.tabelIsLoading = false;
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        const start = (this.pager.pageNo - 1) * this.pager.pageSize;
        data.data.forEach(it => {
          it.borderedQty = 1;
          it.invStatus = it.invStatusId;
          it.bigUom = it.uom;
          it.uid = this.genID(10);
        });
        this.selRows = []; //切换页码清空勾选的行
        this.onlyShowChecked = false;
        this.pager.count = data.page.totalCount;
        this.dataCache = data.data; //缓存列表数据，前端分页
        this.dataList = data.data.slice(start, start + this.pager.pageSize);
      });
    },
    //生成不重复id/后端返回的列表没有唯一键
    genID(length) {
      return Number(
        Math.random()
          .toString()
          .substr(3, length) + Date.now()
      ).toString(36);
    },
    editRow(item) {
      debugger
      this.rowSelectionType = "radio";
      this.searchIpt = item.model;
      this.selRow = item;
      this.isVisible = true;
      this.getData();
    },
    addProduct(productList) {

      productList = productList.filter(item => {
        if (item.productCode && item.productName) {
          return true;
        } else {
          // this.$message.warning("存在信息不全的产品，已去除！");
          const msg = this.$configTotal({
            route: this.$route.fullPath,
            id: '14180000067'
          })
          this.$message.warning(msg && msg.msgDesc);
          return false;
        }
      });
      if (this.rowSelectionType == "checkbox") {
        const models = this.productList.map(it => it.productCode);
        productList.forEach(it => {
          if (!models.includes(it.productCode)) {
            it.checked = true;
            it.itemId = "";
            it.bprice = it.retailPrice
            it.message = ''
            it.MachineCode = ''
            it.ProNum = Number(0)
            models.push(it.productCode);
            this.productList.push(it);
          } else {
            this.productList.forEach(item => {
              if (item.productCode == it.productCode) {
                item.ProNum = Number(item.ProNum)
                item.ProNum += 1
              }
            })
          }
        });
      } else {
        if (productList.length > 0) {
          let pro = productList[0];
          const index = this.productList.findIndex(
            it => it.productCode == this.selRow.productCode
          );
          pro = {
            ...pro,
            checked: true,
            itemId: "",
            bprice: this.productList[index].bprice
          };
          this.$set(this.productList, index, pro);
        }
      }


    },

    showModal() {
      this.visible = true;
    },
    handleOk(e) {

      this.visible = false;
    },

    onSubmit() {
      //form
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alert("submit!");
        } else {

          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    // 订单提交
    submitOrder() {
      if (!this.receiveID) {
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000070'
        })
        this.$message.warning(msg && msg.msgDesc);
        return;
      }
      if (!this.adressName) {
        this.$message.warning('收货地址不能为空');
        return;
      }
      if(!this.trans){
        this.$message.warning('请选择拉货方式');
        return;
      }
      if(this.productList.length<=0){
        this.$message.warning('请选择商品');
        return;
      }
      let sumbitListOld = []
      this.productList.forEach( item => {
        sumbitListOld.push(...(item.list))
      })
      let  sumbitListNew = []
      sumbitListOld.forEach(proItem => {
        // 给后端传值 如果有鉴定单号机编码前十二位和选择的产品的物料号不一致  截取12位传给后端
        proItem.matnrFromVerifyCode = ''
        if(proItem.identificationSheeNo && (proItem.oldMaterialCode&&proItem.machineCode )&& (proItem.oldMaterialCode!== proItem.machineCode.slice(0,12))){
          proItem.matnrFromVerifyCode = proItem.machineCode.slice(0,12)
        }
        sumbitListNew.push({
          matnr:proItem.oldMaterialCode?proItem.oldMaterialCode:proItem.materialCode,
          orgCode: proItem.orgCode,
          materialGroupCode: proItem.materialGroupCode,
          productModel: proItem.modelName,
          machineCode: proItem.machineCode,
          crmIdentificationCode: proItem.identificationSheeNo,
          identificationDate: proItem.identificationShee,
          remark: proItem.remark,
          returnMachineType: proItem.reverseTypeId,
          gicWarehouseName:proItem.gicWarehouseName?proItem.gicWarehouseName:'',
          gicWarehouse:proItem.gicWarehouse?proItem.gicWarehouse:'',
          gicOwner:proItem.ownerNumber?proItem.ownerNumber:'',
          gicPriceprottype:proItem.invStatusTypeName?proItem.invStatusTypeName:'',
          "matnrFromVerifyCode":proItem.matnrFromVerifyCode?proItem.matnrFromVerifyCode:'',
          "specialFlag":proItem.duplicateRecord?proItem.duplicateRecord:'',
          "duplicateCause":proItem.repeatedReasons?proItem.repeatedReasons:'',
          gicInInvDate:proItem.gicInInvDate?proItem.gicInInvDate:'',
        })
      })
      let sumbitObj = {
        "organizationId": this.orgzzId ,//销售组织id
        "organizationCode": this.receiveID,//销售组织编码
        "invoiceMdmCode": this.shopId, // 开票方
        "officeId":this.officeId,// 办事处
        // "customerName": this.shopName, // 开票方名称
        "materialGroupCode": this.wlzid,//物料组code
        "returnAddressId": this.addressId, // 退货地址id
        "contactPerson": this.contacts, // 联系人
        "contactPhone": this.phoneCon, // 联系名称
        "pickUpTypeId": this.trans, // 拉货方式
        identificationFlag: this.IdentificatioSheetNo==1?0:1, // 有无鉴定单号 1有 0无
        productInfoList:sumbitListNew, // 产品信息
      }
      this.isLoading  = true
      getCustomerMarketModel(
        {
          custMdmCode: this.$store.state.user.userInfo.customer.customerCode,
          matklCode: this.wlzid,
          orgId: this.orgzzId,
        }
      ).then(res => {
        const { data } = res.data;
        if(!data){
          this.isLoading  = false
          this.$message.warning("商家该物料组的合作关系已冻结，不可发起残次退。")
        }else{
          this.submitClick(sumbitObj)
        }
      }).catch(error => {
        this.isLoading  = false
      })
    },
    submitClick(sumbitObj){
      submit(sumbitObj).then(res => {
        if(res.data.code == 0) {
          this.isLoading  = false
          this.$router.push({
            path: "/hisenseReturn/success",
            query: {
              b2bOrderCode: res.data.data,
              type: "HXTH",
              page: "new"
            }
          });
        } else if(res.data.code !== 400) {
          this.$message.warning(res.data.msg)
          this.isLoading  = false
        } else {
          this.isLoading  = false
        }

      }).catch(error => {
        this.isLoading  = false
      })
    },
    // 下载模板
    downFile() {
      const link = document.createElement("a");
      link.href =this.IdentificatioSheetNo == 0? this.downloadUrlHas : this.downloadUrlNo;
      link.setAttribute("download", "残次退申请模板.xls");
      link.click();
    },

    // 导入商品信息
    beforeUpload(file) {
      this.fileList = []
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    handleChange({ fileList }) {
      this.fileList = []
      this.fileList = fileList;
      this.fileList = this.fileList.slice(-1);
      if (this.fileList.length > 0) {
        this.importExcel(this.fileList)
      }
    },
    // 导入数据
    importExcel(fileList) {
      if(!this.orgzzId){
        this.$message.warning('销售组织不能为空，请选择')
        return
      }
      if(!this.wlz){
        this.$message.warning('物料组不能为空，请选择')
        return
      }
      let formData = new FormData()
      formData.append('materialCode', this.wlz);
      formData.append('organizationCode', this.orgzzId);
      formData.append('organizationName', this.receive);
      formData.append('identificationFlag', this.IdentificatioSheetNo == 0 ? 1:0);
      formData.append('file', fileList[0].originFileObj);
      let config = {
        headers: { 'content-type': 'multipart/form-data', },
      }
      this.pageLoadFlag = true
      // 发送请求
      const BASE_API = publicPath;
      axios.post(BASE_API + '/defective/uploadFile.nd', formData, config).then(res => {
        if(res.data.code !== 0) {
          this.$message.warning(res.data.msg)
          this.pageLoadFlag = false
          return
        }
        this.pageLoadFlag = false
        let importList = res.data.list
        let list = []
        importList.forEach(item => {
          if (list !== null && list.length > 0) {
            for (let i = 0; i <= list.length; i++) {
              if (list[i].model === item.productName) {

              } else if (list[i].model !== item.productName && i === (list.length - 1)) {
                list.push({
                  gicWarehouseName:item.gicWarehouseName?item.gicWarehouseName:'',
                  gicWarehouse:item.gicWarehouse?item.gicWarehouse:'',
                  message: item.remark,
                  MachineCode: item.machineCode,
                  materialGroupCode: item.materialCode|| '',
                  identificationSheeNo:item.identificationCode,
                  identificationDate:item.identificationDate,
                  organizationName: item.organizationName || '',
                  model: item.productModel,
                  brandName:item.brandName|| '',
                  zzprdmodel: item.productModel,
                  returnTypeId:  item.returnTypeId,
                  returnTypeName: item.returnTypeName,
                  ownerNumber:item.ownerNumber?item.ownerNumber:'',
                  invStatusTypeName:item.invStatusTypeName?item.invStatusTypeName:'',
                  checked: true,
                })
                break
              }
            }
          } else {
            list.push({
              gicWarehouseName:item.gicWarehouseName?item.gicWarehouseName:'',
              gicWarehouse:item.gicWarehouse?item.gicWarehouse:'',
              message: item.remark,
              MachineCode: item.machineCode,
              materialGroupCode: item.materialCode|| '',
              identificationSheeNo:item.identificationCode,
              identificationDate:item.identificationDate,
              organizationName: item.organizationName || '',
              model: item.productModel,
              brandName:item.brandName|| '',
              zzprdmodel: item.productModel,
              returnTypeId:  item.returnTypeId,
              returnTypeName: item.returnTypeName,
              ownerNumber:item.ownerNumber?item.ownerNumber:'',
              invStatusTypeName:item.invStatusTypeName?item.invStatusTypeName:'',
              checked: true,
            })
          }
        })
        // 查询商品详情
        list.forEach(proItem => {
          let data = {
            cisCode: this.$store.state.user.userInfo.customer.cisCode,
            terms: {
              model: proItem.model,
              warehouseId: this.receiveFHID,
              bigQtyMin: 0,
              orgId:  this.orgzzId,
              invStatusName: "T"
            },
            page: {
              pageNo: 1,
              pageSize: 100
            }
          }
          let proList = {}
          getProductListNew(data).then(res => {
            var ck = JSON.parse(res.data)
            if (ck.data.length == 0) {
              this.$message.warning(proItem.model + '暂无此型号数据，请重新导入', 5)
            } else {
              for (let i = 0; i <= ck.data.length; i++) {
                if (ck.data[i]) {
                  if (ck.data[i].model == proItem.model || ck.data[i].zzprdmodel === proItem.model) {
                    proList.count = 0 // 数量
                    proList.modelName= ck.data[i].zzprdmodel // 产品型号
                    proList.materialCode= ck.data[i].materialCode // 物料编码
                    proList.colour= ck.data[i].colour // 颜色
                    proList.volume= ck.data[i].volume // 体积
                    proList.invStatusId=ck.data[i].invStatusId // 质量等级code
                    proList.invStatusName= ck.data[i].invStatusName // 质量等级名称
                    proList.bigQty = ck.data[i].bigQty // 可用库存
                    proList.productId = ck.data[i].productId // 产品id
                    proList.materialGroupCode = Number(ck.data[i].materialGroupCode) // 物料号
                    proList.orgCode = Number(ck.data[i].orgCode) // 组织
                    proList.brandName = ck.data[i].brandName
                    proList.gicWarehouseName = ck.data[i].gicWarehouseName
                    proList.gicWarehouse = ck.data[i].gicWarehouse
                    proList.ownerNumber = ck.data[i].ownerNumber?ck.data[i].ownerNumber:'',
                    proList.invStatusTypeName = ck.data[i].invStatusTypeName?ck.data[i].invStatusTypeName:'',

                      proList.list = [
                        {
                          ownerNumber:ck.data[i].ownerNumber,
                          invStatusTypeName:ck.data[i].invStatusTypeName,
                          modelName: ck.data[i].zzprdmodel , // 产品型号
                          materialCode: ck.data[i].materialCode , // 物料编码
                          machineCode: proItem.MachineCode, // 机编码
                          identificationSheeNo: proItem.identificationSheeNo, // CRM鉴定单号
                          identificationShee:  proItem.identificationDate, // 鉴定日期
                          reverseTypeId: proItem.returnTypeId, // 返机类型id
                          reverseTypeName: proItem.returnTypeName, // 返机类型name
                          remark: proItem.message, // 备注
                          gicWarehouseName:ck.data[i].gicWarehouseName,
                          gicWarehouse:ck.data[i].gicWarehouse,
                          colour:'',
                          volume:'',
                          invStatusId:'',
                          invStatusName:'',
                          brandName:'',
                          productId:'',
                          orgCode: Number(ck.data[i].orgCode),
                          bigQty:'',
                          materialGroupCode: Number(ck.data[i].materialGroupCode),
                          disabled: true, // 机编码 编辑控制
                          jdDisabled: false,  // CRM鉴定单号能不能编辑控制
                        }
                      ]
                    const models = this.productList.map(it => it.modelName);
                    // 如果没有想同型号的的数据 就push一条
                    if(models.includes(proList.modelName)) {
                      // 如果有的话  就向有的数据中新增一条
                      this.productList.forEach(item => {
                        if (item.modelName == proList.modelName) {
                          if(item.list.length > 0){
                            for(let i = 0; i < item.list.length; i++ ) {
                              if(item.list[i].machineCode == proItem.MachineCode){
                                this.$message.warning('列表中已经有相同机编码数据，请修改后重新导入')
                                return
                              }
                            }
                          }
                          item.count += 1
                          item.list.push(...(proList.list))
                          this.$forceUpdate()
                        }
                      })
                    }
                    if(!models.includes(proList.modelName)){
                      proList.count += 1
                      this.productList.push((JSON.parse(JSON.stringify(proList))))
                      this.$forceUpdate()
                    }
                    break
                  }
                }
              }
            }

            // 根据机编码获取数量
            // proItem.ProNum = proItem.MachineCode.split('\n').length
          })
        })
        this.pageLoadFlagaa = false
      }).catch(error=>{
        this.pageLoadFlag = false
      })
    },
    // 获取子组件选中商品
    sonCheckList(data) {
      let cheacked = this.sonChectItme.indexOf(data);
      if (this.sonChectItme.indexOf(data) == -1) {
        this.sonChectItme.push(data);
      } else {
        this.sonChectItme.splice(cheacked, 1);
      }
    },
    goCar() {
      this.$router.push("/hisenseReturn/defectiveReturnListNew");
    },

    // 新增产品弹框
    openAdd() {
      if (!this.receiveID) {
        // this.$message.warning("请选择销售组织");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000070'
        })
        this.$message.warning(msg && msg.msgDesc);
        return;
      }
      this.showModalIdent = true
    },
  }
};
